@import '../../../style/abstract/variables';

.btn {
    font-size: 1.5em;
    color: $col-secondary-light;
    border: none;
    outline: none;
    background-color: $col-secondary-dark;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    box-shadow: 0 0 8px rgba(#000, .6);
    transform: scale(1);
    cursor: pointer;

    transition: all .2s ease-in-out;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 12px rgba(#000, .3);
    }
}