@import '../../../../style/abstract/_variables';
@import '../../../../style/abstract/mixins';

.navItem {
    list-style: none;
    // padding: 1rem 0;
    position: relative;
    // border-radius: 5rem;
    // overflow: hidden;
    text-align: center;

    &:not(:last-child) {
        margin-bottom: 10%;
    }

    &::before {
        content: '';
        background-color: rgba($col-primary-dark, .8);
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 20px;
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        transition: height .2s ease-in-out 0s, 
            width .2s cubic-bezier(0.16, 1, 0.3, 1) .2s, 
            background-color 1s;
    }

    &:hover::before {
        height: 100%;
        width: 100%;
    }

    &:active::before {
        background-color: rgba($col-secondary-light, .2);
    }

    //hover background
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%) scale(1);
    //     width: .1%;
    //     height: 10.1px;
    //     opacity: 1;
    //     pointer-events: none;
    //     background-color: rgba($col-primary-dark, .8);

    //     transition: all 2.3s;
    // }

    // &:hover::before {
    //     opacity: 1;
    //     transform: translate(-50%, -50%) scale(1000);
    // }

    // &::after {
    //     content: '';
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     border-radius: 10rem;
    //     transform: translate(-50%, -50%) scale(1);
    //     width: .1px;
    //     height: .1px;
    //     opacity: 0;
    //     pointer-events: none;
    //     background-color: rgba($col-secondary-light, .8);

    //     transition: all .2s;
    // }

    // &:hover:active::after {
    //     opacity: 1;
    //     transform: translate(-50%, -50%) scale(2000, 700);

    //     transition: all 3s;
    // }
    
    a {
        position: relative;
        text-decoration: none;
        color: inherit;
        text-transform: uppercase;
        letter-spacing: 2px;
        z-index: 100;
        display: block;
        width: 100%;
        line-height: 3em;

        transition: color .3s ease-in-out;

        &.active {
            color: $col-secondary-dark
        }
    }

    @include respond(tab-port) {
        &::before,
        &::after {
            content: none;
        }

        &:not(:last-child) {
            margin-bottom: 2.5%;
        }
    }
}