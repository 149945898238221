@import '../../../style/abstract/variables';
@import '../../../style/abstract/mixins';
.sidebar {
    width: 100%;
    min-height: #{"max(100%, 70rem)"};
    padding-top: 8rem;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 0 10px rgba(#000, .25);

    background-color: $col-primary-light;
    color: $col-primary-light-text;

    .clock {
        position: absolute;
        top: 1.5rem;
    }

    .spaceSelect{
        align-self: center;
        margin: 2rem 2rem 0;
        max-width: 15rem;
        font-size: .8em;
    }

    .userArea {
        display: flex;
        flex-direction: column;
        align-items: center;

        .userImg {
            width: 25%;
            border-radius: 50%;
            margin-bottom: 2rem;
        }

        .username {

        }
    }

    .navArea {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .plusBtn {
        margin-top: 2rem;
        text-align: center;
    }

    .bottomArea {
        width: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto 3rem;

        .settingsIcon {
            font-size: 1.2em;
            border-radius: 50%;
            width: 1.5em;
            height: 1.5em;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            transition: background-color .2s;
            &:hover {
                background-color: rgba($col-primary-dark, .8);
            }
        
        }
    }
}