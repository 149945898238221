@import '../../../style/abstract/variables';
@import '../../../style/abstract/mixins';

.wrapper {
    position: relative;
    height: 30rem;
    @include respond(phone) { height: 40rem;}
    max-height: calc(100vh - 36vmin - 150px);
    overflow: hidden;

    .angleLeft,
    .angleRight {
        @include mobileKeyboardOpen { display: none; }
        cursor: pointer;
        z-index: 1000;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $col-primary-light;
        font-size: 4rem;

        transition: .3s all;
        &.hidden {
            opacity: 0;
            visibility: hidden;
        }

        &:active {
            transform: translateY(-50%) scale(.9);
        }
    }
    .angleLeft {
        left: 0;
    }
    .angleRight {
        right: 0;
    }

    .pages {
        height: 100%;
        display: flex;
        transition: .3s all;

        .page {
            height: 100%;
            overflow-y: auto;
            flex-basis: 100%;
            max-width: 100%;
            flex-shrink: 0;
            padding: 1.5rem;
            // scroll-behavior: smooth;
            @include mobileKeyboardOpen { padding: 0; }

            .spacesSelect {
                display: flex;
                align-items: stretch;
                flex-direction: column;

                .space {
                    width: 100%;
                    font-size: 2rem;
                    font-weight: 600;
                    letter-spacing: 1px;
                    cursor: pointer;
                    padding: .5rem 2rem;
                    box-shadow: 0 0 3px rgba(#000, .3);
                    border-radius: 6px;

                    &:not(:last-child) {
                        margin-bottom: 2rem;
                    }
                    &:last-child {
                        margin-bottom: 1rem;
                    }

                    transition: all .2s;
                    &:hover {
                        letter-spacing: 2px;
                        background-color: rgba(#000, .1);
                    }

                     &.spaceSelected {
                        background-color: rgba(#000, .1);
                     }
                }
            }

            .subjectScrollPane {
                display: flex;
                align-items: stretch;
                flex-direction: column;
                overflow: hidden;
                width: 100%;
                padding: 0 3rem;
                padding-bottom: 2rem;
                @include respond(phone) { padding: 0 2rem;}
                font-size: .7em;

                div span {
                    white-space: normal;
                }

                .nosubs {
                    position: absolute;
                    top: 50%;
                    left: 150%;
                    transform: translate(-50%, -50%);
                    font-size: 1.5rem;
                    width: 80%;
                    text-align: center;
                }
            }

            &:nth-child(2) {
                padding-right: 2.5rem;
                word-wrap: break-word;
                @include respond(phone) { font-size: .9em;}
            }

            &:nth-child(3) {
                display: flex;
                justify-content: center;
                align-content: center;
                @include respond(small-phone) { align-content: space-around;}
                flex-wrap: wrap;
                @include respond(small-phone) { padding: 25% 1rem 15%;}
                @include mobileKeyboardOpen { padding: 0; }

                h3 {
                    flex: 1 0 100%;
                    text-align: center;
                    margin-bottom: 4rem;
                    @include mobileKeyboardOpen { display: none; }
                }

                .inAndStar {
                    width: 85%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include respond(small-phone) { flex-wrap: wrap; justify-content: center;}

                    .typeInput {
                        width: 85%;
                        @include respond(small-phone) { 
                            flex: 1 0 100%;
                            width: 100%;
                        }
                    }

                    .star,
                    .bell {
                        cursor: pointer;
                        font-size: 1.3em;
                        @include respond(small-phone) { margin-top: 3rem;}
                    }

                    .bell {
                        @include respond(small-phone) { 
                            margin-right: 1.5rem;
                        }
                    }
                    .star {
                        @include respond(small-phone) { 
                            margin-left: 1.5rem;
                        }
                    }
                }
            }

            &:nth-child(4) {
                padding: 0 5rem;
                @include respond(phone) { padding: 0 2.5rem;}
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                overflow-y: auto;

                h3 {
                    margin-bottom: 1rem;
                }

                .shortcuts {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                    button, .firstDeadlineInput {
                        position: relative;
                        margin: .5rem 1rem;
                        @include respond(phone) { margin: .4rem .25rem;}
                    }

                    .firstDeadlineInput {
                        @include respond(phone) { margin: 1rem .25rem;}
                    }
                }
                
            }

            &:nth-child(5) {
                padding: 0 5rem;
                @include respond(phone) { padding: 0 2.5rem;}
                display: flex;
                flex-direction: column;
                justify-content: center;
                overflow-y: auto;

                h3 {
                    margin-bottom: 1rem;
                }

                .intervalOptions {
                    color: white;
                }
            }

            &:nth-child(6) {
                padding: 0 5rem;
                @include respond(phone) { padding: 0 2.5rem;}
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                overflow-y: auto;

                h3 {
                    margin-bottom: 3rem;
                }
            }

            &:nth-child(7) {

                h3 {
                    @include mobileKeyboardOpen { display: none; }
                }

                .addTextField {
                    margin: 5rem auto 0;
                    @include mobileKeyboardOpen { margin: 0 auto; }
                    width: 80%;
                    height: 40%;

                    textarea {
                        width: 100%;
                        min-height: 100%;
                    }
                }

                .error {
                    color: red;
                }
            }
        }
    }
}