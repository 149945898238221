@import '../abstract/_variables';

.util {
    
    &-card {
        background-color: #fff;
        border-radius: 3rem;
        padding: 4rem;
        box-shadow: 0 0 4rem rgba(#000, .08);
    }

    &-bg-light {
        background-color: lighten($color: $col-primary-light, $amount: 75);
    }

    &-center-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-margin-bottom {
        &-medium {margin-bottom: 2rem;}
    }
}