// COLORS

$col-primary-dark:      #222831;
$col-primary-dark-text: #fff;
$col-primary-light:     #30475e;
$col-primary-light-text: #fff;
$col-secondary-dark:    #c1a57b;
$col-secondary-dark-text: #fff;
$col-secondary-light:   #ececec;
$col-secondary-light-text: #000;

$col-turquoise:     #1abc9c;
$col-emerald:       #2ecc71;
$col-green-sea:     #16a085;
$col-nephritis:     #27ae60;
$col-peter-river:   #3498db;
$col-belize-hole:   #2980b9;
$col-amethyst:      #9b59b6;
$col-wisteria:      #8e44ad;
$col-wet-asphalt:   #34495e;
$col-midnight-blue: #2c3e50;
$col-sun-flower:    #f1c40f;
$col-orange:        #f39c12;
$col-carrot:        #e67e22;
$col-pumpkin:       #d35400;
$col-alizarin:      #e74c3c;
$col-pomegranate:   #c0392b;
$col-clouds:        #ecf0f1;
$col-silver:        #bdc3c7;
$col-soncrete:      #95a5a6;
$col-asbestos:      #7f8c8d;

$col-danger:        #ff3344;
$col-danger-text:     #fff;
$col-check:           #7e7;
$col-cta:           #2ecc71;

/********* LAYOUT ***********/

$sidebar-default-width:     20%;
$sidebar-min-width:         20rem;
$sidebar-max-width:         25rem;

$drawer-toggle-width:       6rem;
$drawer-toggle-offset:      2rem;
$col-backdrop:              rgba(#000, .2);

$max-container-width: 150rem;
$max-container-width-phone: 80vw; 

/********* COMPONENTS ********/

$default-border-radius:     15px;

/********* DESIGN ************/

$default-box-shadow:        1px 2px 3px rgba(#000, .3);

$bs-above:                  0 0 3px rgba(#000, .3);
$bs-above-hover:            0 0 5px rgba(#000, .2);
$bs-above-white:            0 0 3px rgba(#fff, .3);
$bs-above-hover-white:      0 0 5px rgba(#fff, .2);

$hero-gradient:             linear-gradient(140deg, rgba(34,40,49,1) 0%, rgba(48,71,94,1) 28%, rgba(133,163,193,1) 100%);
$footer-gradient:           linear-gradient(to bottom, rgba(34,40,49,1) 0%, rgba(48,71,94,1) 28%, rgba(133,163,193,1) 100%);

$settings-line:             2px solid rgba(#000, .3);