@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 400px: Small Phone
401 - 600px:      Phone
601 - 900px:    Tablet portrait
901 - 1200px:   Tablet landscape
1201 - 1800px:  Desktop (normal styles appy)
1801+px:        Big Desktop

$breakpoint argument choices:
- small-phone
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@mixin respond($breakpoint) {
    @if $breakpoint == small-phone {
        @media (max-width: 25em) { @content };
    }
    @if $breakpoint == phone {
        @media (max-width: 37.5em) { @content};
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) { @content};
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) { @content};
    }
    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) { @content};
    }
}

@mixin minrespond($breakpoint) {
    @if $breakpoint == phone {
        @media (min-width: 37.5em) { @content };
    }
}

@mixin mobileKeyboardOpen() {
    @media (max-height: 25em) and (max-width: 37.5em) { @content };
}