@import '../../../style/abstract/variables';
@import '../../../style/abstract/mixins';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;

    .input {
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid $col-primary-light;
        line-height: 2em;

        &.transparent {
            border: none;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 2em;

            &:not(:placeholder-shown) {
                cursor: pointer;
            }
        }
    }

    .label {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 2em;
        line-height: .8em;
        opacity: 1;

        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;

        transition: all .2s ease-out;
    }

    .simpleCheckbox {

    }

    .textarea {
        font-family: inherit;
        font-size: .9em;
        padding: .2rem .5rem;
        background-color: rgba(#fff, .7);
        outline: none;
        border: 2px solid rgba($col-primary-dark, .7);
        border-radius: 2px;
        box-shadow: 0 0 5px rgba(#000, .3), inset 0 0 0 rgba(#fff, .3);

        transition: all .3s;
        &:focus {
            background-color: rgba(#fff, .9);
            box-shadow: 0 0 0 rgba(#000, .3), inset 0 0 3px rgba(#fff, .3);
        }
    }

    .input.transparent {
        border-radius: 5px;
    }

    .input.transparent::placeholder {
        opacity: 0;
    }

    .input.transparent + label {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        width: 100%;
        text-align: center;
    }

    .input:not(.transparent):not(:placeholder-shown) + label {
        transform: translate(-1rem, -100%);
        font-size: 1.3rem;
        color: $col-primary-dark;
    }

    .input.transparent:not(:placeholder-shown) + label {
        opacity: 0;
    }

    .input {
        transition: box-shadow .3s;
    }
    .input.glowRed {
        box-shadow: inset 0 0 30px rgba(#b00,.4),
                    0 0 7px rgba(#b00, .4);
    }

    &Select {
        margin-top: -.5rem;

        @include respond(tab-port) {
            margin-top: -1rem;
        }

        .selectVisual {
            cursor: pointer;
            margin-top: .5rem;
            @include respond(tab-port) {
                margin-top: 1rem;
            }

            &:not(:last-child) {
                margin-right: .8rem;
            }

            input {
                position: absolute;
                height: 0;
                width: 0;
                opacity: 0;

                &:checked + span {
                    background-color: $col-secondary-dark;
                }
            }

            span {
                font-size: .85em;
                background-color: $col-primary-light;
                padding: .1em .4em;
                border-radius: 50rem;
                border: 2px solid $col-primary-light;
                display: inline-block;
                text-align: center;

                transition: all .1s ease-out;

                @include respond(tab-port) {
                    font-size: 1.1em;
                }
            }

            &.minSize span {
                min-width: 5em;

                @include respond(tab-port) {
                    min-height: 2.5em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

.checkWrapper {

    .checkbox {
        cursor: pointer;
        position: relative;
        display: block;
        width: 2em;
        height: 2em;
        input {
            position: relative;
            appearance: none;
            border: none;
            outline: none;
            width: 0;
            height: 0;
            opacity: 0;

            &:checked + span {
                animation: animCircle .2s forwards;

                &::before {
                    transform: translateY(-40%) rotate(45deg) scale(1);
                    opacity: 1;
                    transition-delay: .2s;
                }
            }

            &:not(:checked) + span {
                animation: animCircle2 .2s forwards;

                &::before {
                    transform: translateY(-40%) rotate(45deg) scale(0);
                    opacity: 0;
                }
            }
        }    

        span {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border: .15em solid rgba($col-primary-dark, .7);
            border-radius: 50%;
            
            transition: all .3s;

            &:hover {
                border-color: $col-primary-dark;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 50%;
                height: 150%;
                border-right: .3em solid;
                border-bottom: .3em solid;
                border-color: $col-check;
                border-radius: .35em;
                transform-origin: bottom right;
                transform: translateY(-40%) rotate(45deg);

                transition: all .2s ease-out;
            }
        }
    }
    
}

@keyframes animCircle{
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes animCircle2{
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(.5);
    }
    100% {
        transform: scale(1);
    }
}