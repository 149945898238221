@import '../../../style/abstract/_variables';
@import '../../../style/abstract/mixins';

.drawer {

    .toggle {
        position: fixed;
        top: $drawer-toggle-offset;
        right: $drawer-toggle-offset;
        width: $drawer-toggle-width;
        height: $drawer-toggle-width;
        z-index: 19;
    }

    .menu {
        position: fixed;
        top: #{$drawer-toggle-offset + $drawer-toggle-width * .5};
        right: #{$drawer-toggle-offset + $drawer-toggle-width * .5};
        background-color: $col-secondary-light;
        color: $col-secondary-light-text;
        width: 70%;
        padding: 4rem 1rem 1rem;
        border-radius: 1rem;
        opacity: 0;
        transform-origin: top right;
        transform: scale(.01);
        z-index: 15;

        transition: all .3s cubic-bezier(0.34, 1.56, 0.64, 1);

        .userArea {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 50%;
            margin: auto auto 2.5rem;
    
            .userImg {
                width: 25%;
                border-radius: 50%;
            }
    
            .username {
    
            }
        }

        .spaceSelect {
            text-align: center;
            font-size: .8em;
            margin: 1.5rem 0;
        }
    }

    .plusBtn {
        margin-top: 2rem;
        text-align: center;
    }

    .bottomArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 75%;
        margin: 3rem 0 0 auto;


        button {
            font-size: 1rem;
            @include respond(tab-port) { font-size: 1.5rem;}
            @include respond(phone) { font-size: 1.2rem;}
        }

        .settingsIcon {
            font-size: 2rem;
            cursor: pointer;
            padding: .5rem;
            border-radius: 50%;
            width: 1.5em;
            height: 1.5em;
            display: flex;
            justify-content: center;
            align-items: center;

            transition: background-color .2s;
            &:active, &:hover {
                background-color: rgba($col-primary-dark, .5);
            }
        }
    }

    .backdrop {
        position: fixed;
        top: #{$drawer-toggle-offset + $drawer-toggle-width * .5};
        right: #{$drawer-toggle-offset + $drawer-toggle-width * .5};
        width: 100vw;
        height: 100vh;
        background-color: $col-backdrop;
        transform-origin: top right;
        transform: translate(0, 0) scale(.01);
        opacity: 0;
        z-index: 12;

        transition: all .2s;
    }

    &.toggled {

        .menu {
            opacity: 1;
            transform: scale(1);
        }

        .backdrop {
            opacity: 1;
            transform: translate(50vw, -50vh) scale(5);
        }
    }
}