@import '../../style/abstract/_variables';
@import '../../style/abstract/mixins';

.navDesktop {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: $sidebar-default-width;
    min-width: $sidebar-min-width;
    max-width: $sidebar-max-width;
    overflow-x: hidden;
    overflow-y: auto;

    @include respond(tab-port) {
        display: none;
    }
}

.navMobile {
    display: none;

    @include respond(tab-port) {
        display: block;
    }
}

.main {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    display: block;
    width: calc(100% - #{$sidebar-default-width});
    min-width: calc(100% - #{$sidebar-max-width});
    max-width: calc(100% - #{$sidebar-min-width});

    .plusBtn {
        font-size: 1.2em;
        position: fixed;
        bottom: 3rem;
        right: calc((100% - #{$sidebar-default-width}) * 0.5);
        transform: translateX(-50%);

        @include respond(tab-port) { bottom: 7.5rem;}
    }

    @include respond(tab-port) {
        width: 100vw;
        min-width: unset;
        max-width: unset;
    }
}