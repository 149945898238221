@import '../../../style/abstract/_variables';

.btn {
    backface-visibility: hidden;

    &,
    &:link,
    &:visited {
        text-transform: uppercase;
        text-decoration: none;
        font-family: inherit;
        border: none;
        border-radius: 2em;
        outline: none;
        background-color: $col-secondary-dark;
        color: $col-secondary-dark-text;
        padding: .4em 2em;
        cursor: pointer;
        box-shadow: 2px 4px 1em rgba(#000, .3);
        display: inline-block;

        &.danger {
            background-color: $col-danger;
            color: $col-danger-text;
        }

        transition: all .2s;
    }

    &:hover {
        transform: translateY(-1px);
        box-shadow: 3px 5px 1.3em rgba(#000, .3);
    }

    &:active,
    &:focus {
        outline: none;
        transform: translateY(2px);
        box-shadow: 1px 2px .8em rgba(#000, .35);
    }

    &.marked {
        background-color: darken($col-secondary-dark, 20%);
    }

}