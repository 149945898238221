@import '../../../style/abstract/variables';
@import '../../../style/abstract/mixins';

.row {
    position: relative;
    padding: 1rem 3rem;
    font-size: 1.5em;
    border-radius: $default-border-radius;
    cursor: pointer;
    transform: scale(1);
    transition: all .3s ease-out;
    box-shadow: 1px 2px 4px rgba(#000, .5);

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    &:not(.noHover):hover {
        transform: scale(1.1);
        box-shadow: .5px 1px 6px rgba(#000, .3);
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: inherit;
        border-radius: $default-border-radius;
        transform: scale(1);
        // filter: blur(1px);

        transition: all .3s ease-out;
    }

    &:not(.noHover):hover::before {
        filter: blur(2px);
    }

    &.outline {
        border: 5px double #{$col-primary-dark};
        border-style: double;
    }

    .icon {
        position: absolute;
        right: 2rem;
    }

    .title {
        font-size: 1.2em;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-weight: bold;
        
        @include respond(small-phone) {
            white-space: nowrap;
        }
    }
}