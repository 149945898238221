@import '../abstract/mixins';
@import '../abstract/variables';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 62.5%; // =1rem; to allow the user to change the font size and react to this setting */
    scroll-behavior: smooth;

    @include respond(tab-land) {
        font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
    }
    @include respond(tab-port) {
        font-size: 50%; //1rem = 8px, 8/16 = 50%
    }
    @include respond(big-desktop) {
        font-size: 57.5%; //1rem = 12px, 12/16 = 75%
    }
}

body {
    box-sizing: border-box;

    font-size: 1.5rem;
    font-family: 'Cantarell', sans-serif;
}

::selection {
    background-color: $col-primary-light;
    color: $col-primary-light-text;
    text-shadow: none;
}

h1 {
    font-family: 'Kaushan Script', cursive;
}

h2 {
    font-size: 6rem;
    @include respond(phone) { font-size: 4rem;}
    @include respond(small-phone) { font-size: 3rem;}
    text-align: center;
}